import { PublicClientApplication, LogLevel, InteractionRequiredAuthError } from '@azure/msal-browser';
import Axios from 'axios';

const CLIENT_ID = process.env.REACT_APP_APPID.toString();
export let AuthToken = '';

export const msalConfig = {
    auth: {
        clientId: CLIENT_ID,
        authority: 'https://login.microsoftonline.com/common/'
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
    },
}

export const loginRequest = {
    scopes: ["User.Read", "profile", "openid"],
}

export const msalInstance = new PublicClientApplication(msalConfig);

export const getMsalUserInfo = ()=>{
    const activeUser = msalInstance.getAllAccounts()[0] ? msalInstance.getAllAccounts()[0] : { profile: { 'name': "sd" } };
    return activeUser;
}

export const getMsalLogout = ()=>{
    setTimeout(()=> {
		msalInstance.logoutRedirect();
		localStorage.clear();
	}, 1000);	
}

export const onMsalLogOut = ()=>{
    const userAuthToken = localStorage.getItem("MSAL_TOKEN");
	if (userAuthToken === null) {
		getMsalLogout();
	}
}

export const msalReLogin = ()=>{
    msalInstance.loginRedirect(loginRequest);
}

export const getMsalRefreshedToken = async(csvtRef)=>{
    getMsalToken(()=>{
        setTimeout(()=>{
            if(csvtRef && csvtRef.refreshSignalRToken) csvtRef.refreshSignalRToken()
        }, 30*1000);
    })
}

export const runWithMsal = (msalInstance, app) => {
    if (window === window.parent) {
        msalInstance
            .handleRedirectPromise()
            .then((tokenResponse) => {
                if (!tokenResponse) {
                    const accounts = msalInstance.getAllAccounts();
                    if (accounts.length === 0) {
                        // No user signed in
                        msalInstance.loginRedirect();
                    }
                } else {
                    const { idToken } = tokenResponse;
                    localStorage.setItem("MSAL_TOKEN", idToken);
                    AuthToken = idToken;
                }
                const accounts = msalInstance.getAllAccounts();
                const account = accounts[0];
                msalInstance.acquireTokenSilent({
                    loginRequest,
                    account
                }).then((response) => {
                    AuthToken = response.idToken;
                    localStorage.setItem("MSAL_TOKEN", response.idToken);
                    Axios.defaults.headers['Authorization'] = `Bearer ${AuthToken}`;
                    app();
                }).catch((err) => {
                    console.log(err);
                    if (err instanceof InteractionRequiredAuthError){
                        msalInstance.acquireTokenRedirect({
                            scopes:loginRequest.scopes,
                            account
                        });
                    }
                    //msalInstance.loginRedirect();
                });
            }).catch((err) => {
                // Handle error
                console.error(err);
            });
    }
}

export const getMsalToken = (callback) => {
    const accounts = msalInstance.getAllAccounts();
    const account = accounts[0];
    msalInstance
        .handleRedirectPromise()
        .then((tokenResponse) => {
            msalInstance
                .acquireTokenSilent({
                    loginRequest,
                    account,
                })
                .then((response) => {
                    Axios.defaults.headers["Authorization"] = `Bearer ${response.idToken}`;
                    localStorage.setItem("MSAL_TOKEN", response.idToken);
                    if(callback) callback();
                    return response.idToken;
                })
                .catch((e) => {
                    console.log(e);
                    //msalInstance.loginRedirect();
                });
        })
        .catch((err) => {
            // Handle error
            console.error(err);
        });
}

export const getMSALCachedRIToken = ({countryCode, device_id}) => localStorage.getItem(`auth.token.${countryCode}_${device_id}`)
export const setMSALCachedRIToken = ({countryCode, token, device_id}) => localStorage.setItem(`auth.token.${countryCode}_${device_id}`, token)
export const resetMSALCachedRIToken = ({countryCode, device_id}) => localStorage.removeItem(`auth.token.${countryCode}_${device_id}`)

